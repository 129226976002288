import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBannerList() {
  // Use toast
  const toast = useToast()

  const refBannerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'hamburger', label: '', class: 'text-center' },
    { key: 'position', label: '#', class: 'text-center' },
    { key: 'img', label: 'thumbnail', class: 'text-center' },
    { key: 'name', label: 'banner name' },
    { key: 'type' },
    { key: 'status' },
    { key: 'createdTime', label: 'last updated' },
    { key: 'actions', class: 'text-center' },
  ]
  const perPage = ref(10)
  const totalBanners = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('position')
  const isSortDirDesc = ref(false)
  const draggableOptions = ref({
    animation: 400,
  })

  const dataMeta = computed(() => {
    const localItemsCount = refBannerListTable.value ? refBannerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBanners.value,
    }
  })

  const refetchData = () => {
    refBannerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchBanners = (_, callback) => {
    store
      .dispatch('app-banner/fetchBanners', {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        ascend: !isSortDirDesc.value,
      })
      .then(response => {
        const { data, total } = response.data
        callback(data)
        totalBanners.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching banners list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteBanner = (id) => {
    return store.dispatch('app-banner/deleteBanner', id)
  }

  const swapPosition = (oldPosition, newPosition) => {
    return store.dispatch('app-banner/swapPosition', { oldPosition, newPosition })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBannerStatusVariant = status => {
    if (status === 'Activate') return 'success'
    if (status === 'Inactivate') return 'secondary'
    return 'primary'
  }

  return {
    swapPosition,
    deleteBanner,
    fetchBanners,
    tableColumns,
    perPage,
    currentPage,
    totalBanners,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBannerListTable,
    draggableOptions,
    
    // UI
    resolveBannerStatusVariant,
    refetchData,
  }
}
