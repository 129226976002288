<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <p class="search-label">Search</p>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search by keyword"
              />
            </div>
            <b-button
              variant="primary"
              :to="{ name: 'home-banner-create' }"
            >
              <span class="text-nowrap">Add Banner</span>
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
                class="add-banner-icon"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refBannerListTable"
        class="position-relative mb-0"
        empty-text="No matching records found"
        primary-key="id"
        responsive
        show-empty
        :busy.sync="isBusy"
        :items="fetchBanners"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        v-draggabletable="draggableOptions"
      >
        <!-- Column: User -->
        <template #cell(hamburger)>
          <feather-icon
            icon="MenuIcon"
            size="24"
          />
        </template>

        <!-- Column: User -->
        <template #cell(img)="data">
          <b-img
            v-if="data.item.type === 'Image'"
            :src="data.item.url"
            height="40"
            width="70"
          />
          <video
            v-else
            width="70"
            preload="metadata"
          >
            <source :src="data.item.url">
          </video>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveBannerStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <div class="action-button">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </div>
              </template>
              <b-dropdown-item :to="{ name: 'home-banner-edit', params: { id: data.item.id } }">
                <span class="align-middle ml-50">View &amp; Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDelete(data.item.id, data.item.name)">
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>

    <!-- Pagination -->
    <div class="mt-2 mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing
            </span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap"> of {{ dataMeta.of }} entries </span>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalBanners"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BImg, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownDivider, BDropdownItem, BFormSelect, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useBannerList from './useBannerList'
import bannerStoreModule from '../bannerStoreModule'
import { draggabletable } from "@/libs/vue-draggable.js";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BPagination,
    BImg,

    vSelect,
  },
  data() {
    return {
      isBusy: false
    }
  },
  directives: { draggabletable },
  methods: {
    confirmDelete(id, name) {
      this.$swal({
        title: 'Are you sure to banner?',
        text: 'This will permanently erase your information. You can’t undo action.',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-danger px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return

        const banner = await this.deleteBanner(id);
        const { status: deleteStatus } = banner.data
        if (deleteStatus !== 200) {
          this.makeToast(false, name)
          return;
        }

        setTimeout(() => this.$router.go(0), 300)
        this.makeToast(true, name)
      })
    },
    changePositionToast() {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class="text-danger"
              icon="XCircleIcon"
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class="ml-1 text-danger">Change position failed</h4>
              <div class="ml-1 font-14px">Can not change position</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
    makeToast(success, name) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Delete succeeded!' : 'Delete failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `Banner has been deleted “${name}”.` : 'Can not delete banner.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  setup() {
    const BANNER_APP_STORE_MODULE_NAME = 'app-banner'

    // Register module
    if (!store.hasModule(BANNER_APP_STORE_MODULE_NAME)) store.registerModule(BANNER_APP_STORE_MODULE_NAME, bannerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BANNER_APP_STORE_MODULE_NAME)) store.unregisterModule(BANNER_APP_STORE_MODULE_NAME)
    })

    const {
      swapPosition,
      deleteBanner,
      fetchBanners,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBannerListTable,
      refetchData,
      draggableOptions,

      // UI
      resolveBannerStatusVariant,
    } = useBannerList()

    return {
      swapPosition,
      deleteBanner,
      fetchBanners,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBannerListTable,
      refetchData,
      draggableOptions,

      // UI
      resolveBannerStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-label {
  margin: 0 10px 0 0;
}
.add-banner-icon {
  margin-left: 8px;
}
.action-button {
  border: 1px solid #6e6b7b;
  border-radius: 50%;
  padding: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
