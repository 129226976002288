import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanners(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/bannerList', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBanner(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/bannerById', { params: { id }})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBanner(_, banner) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/bannerCreate', banner)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBanner(_, banner) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/bannerUpdate', banner)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBanner(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/bannerDelete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/homeBanner', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
